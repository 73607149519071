define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.formatDocumentToLocalDate = void 0;
    var formatInputToLocalDate = function (element) {
        return element.querySelectorAll('input[local-date]')
            .forEach(function (e) {
            if (e.value) {
                var date = new Date(e.value);
                e.value = e.hasAttribute('date-only') ? date.toLocaleDateString() : date.toLocaleString();
            }
        });
    };
    var formatTextToLocalDate = function (element) {
        return element.querySelectorAll('span[local-date]')
            .forEach(function (e) {
            if (e.textContent) {
                var date = new Date(e.textContent || "");
                e.textContent = e.hasAttribute('date-only') ? date.toLocaleDateString() : date.toLocaleString();
            }
        });
    };
    function formatDocumentToLocalDate(elementId) {
        if (elementId === void 0) { elementId = ""; }
        var element = elementId ? document.getElementById(elementId) : document.documentElement;
        formatInputToLocalDate(element);
        formatTextToLocalDate(element);
    }
    exports.formatDocumentToLocalDate = formatDocumentToLocalDate;
});
